import img1 from '../../../assets/img/svg/house.svg'
import img2 from '../../../assets/img/svg/crm.svg'
import img3 from '../../../assets/img/svg/clock.svg'

export const OurAdventagesData = [
    [
        {
            img: img1,
            clazz: 'fa fa-truck text-white fa-3x',
            heading: "Drivers",
            para: "Our team of professional drivers operates our fleet, and their expertise adds value to your business. As they represent your company during transportation, they play an important role in maintaining your reputation"
        },
        {
            img: img2,
            clazz: 'fa fa-headset text-white fa-3x',
            heading: "Dispatch",
            para: "Our dispatch team is committed to ensuring that our clients' orders are fulfilled seamlessly by serving as a bridge between the shipper and consignee. They consistently go above and beyond to facilitate communication and ensure smooth delivery."
        },
        {
            img: img3,
            clazz: 'fas fa-dollar-sign text-white fa-3x',
            heading: "Customer Satisfaction",
            para: "As a cohesive unit, our team is dedicated to delivering reliable service and competitive pricing to meet your needs."
        }
    ]
]