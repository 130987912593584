export const FaqsData = [
    {
        'heading': "What is your delivery coverage area?",
        'para': `Our delivery coverage area varies depending on the services that you choose. We mainly have full coverage within the Toronto/GTA regions. Please contact us for more information on our coverage area.`
    },
    {
        'heading': "What is the estimated delivery time for my shipment?",
        'para': `The estimated delivery time will depend on the origin and destination of your shipment, as well as the shipping method you choose. Our team will provide you with an estimated delivery time once your shipment is in transit.`
    },
    {
        'heading': "What happens if my shipment is delayed or damaged?\n",
        'para': `In the event of a delay or damage, our team will work to resolve the issue as quickly as possible. We will do our best to ensure that your shipment is delivered on time and in the condition you expect.`
    },
    {
        'heading': "Can I choose a specific delivery date or time?\n",
        'para': `In most cases, you can choose a specific delivery date or time window for your shipment. However, this option may not be available for all delivery destinations. Our team will work with you to ensure that your shipment is delivered at the time that works best for you.`
    },
    {
        'heading': "Can I change the delivery address of my shipment after it has been sent?\n",
        'para': `In most cases, you can change the delivery address of your shipment if it has not yet been delivered. Please contact our customer support as soon as possible to request a change in delivery address.`
    }
]