import React from 'react'
//ContactInfo Area
const ContactInfo = () => {
    return (
        <>
            <div className="contact_info_wrapper">
                <div className="contact_item">
                    <h5>Our address</h5>
                    <p>1591 Matheson Blvd Mississauga L4W 1H9</p>
                </div>
                <div className="contact_item">
                    <h5>Call us on</h5>
                    <p>Office: (416) 477-1336</p>
                </div>
                <div className="contact_item">
                    <h5>Mail us at</h5>
                    <p>info@delightlogistics.ca</p>
                </div>
            </div>
        </>
    )
}

export default ContactInfo
