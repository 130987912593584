import React from 'react'
import {Link} from 'react-router-dom';
//  OwlCarousel Slider Import
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import RequestQuoteForm from "../../Common/RequestQuoteForm";
import logo from "../../../assets/img/logo.svg";

const HomeBanner = () => {

    let responsive = {
        0: {
            items: 1,
        },
        600: {
            items: 1,
        },
        960: {
            items: 1,
        },
        1200: {
            items: 1,
        },
    }
    return (
        <>
            <section id="homeOne_banner">
                <div className="banner-slider">
                    <OwlCarousel className="owl-theme" responsive={responsive} autoplay={true} autoplayHoverPause={true}
                                 autoplayTimeout={10000}
                                 loop={true} nav={false} dots={window.innerWidth <= 760 ? false : true}>

                        <div className="banner-item banner-img-one">
                            <div className="container">
                                <div className="banner_one_inner">
                                    <div className="row">
                                        <div className="col-lg-8 col-md-12 col-sm-12 col-12">
                                            <div className="banner-text">
                                                <h1>LTL Shipping <br/>Toronto/GTA</h1>
                                                <p style={window.innerWidth <= 760 ? {fontSize: 18} :{}} className='d-none d-sm-flex'>We offer specialized services for businesses and individuals who require less than truckload (LTL) freight deliveries within the Greater Toronto Area (GTA). This means that you can deliver smaller shipments that do not require a full trailer, which is convenient for customers who only need to transport between 1 to 12 pallets.</p>
                                                <p style={window.innerWidth <= 760 ? {fontSize: 18} :{}} className='d-sm-none'>We offer specialized services for businesses and individuals who require less than truckload (LTL) freight deliveries within the Greater Toronto Area (GTA).</p>
                                                <Link className="btn btn-theme" data-toggle="modal" data-target="#exampleModal">Get A Quote</Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="banner-item banner-img-two">
                            <div className="container">
                                <div className="banner_one_inner">
                                    <div className="row">
                                        <div className="col-lg-8 col-md-12 col-sm-12 col-12">
                                            <div className="banner-text">
                                                <h1>Dependable<br/>Service</h1>
                                                <p style={window.innerWidth <= 760 ? {fontSize: 18} :{}} className='d-none d-sm-flex'>Using GPS tracking to monitor our fleet is an excellent way to improve logistics operations and provide reliable service to our customers. GPS technology allows us to keep track of our vehicles' locations and routes in real-time, enabling us to make better-informed decisions and optimize our fleet's performance.</p>
                                                <p style={window.innerWidth <= 760 ? {fontSize: 18} :{}} className='d-sm-none'>Using GPS tracking to monitor our fleet is an excellent way to improve logistics operations and provide reliable service to our customers. GPS technology allows us to keep track of our vehicles' locations and routes in real-time, enabling us to make better-informed decisions and optimize our fleet's performance.</p>
                                                <Link className="btn btn-theme" data-toggle="modal" data-target="#exampleModal">Get A Quote</Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="banner-item banner-img-three">
                            <div className="container">
                                <div className="banner_one_inner">
                                    <div className="row">
                                        <div className="col-lg-8 col-md-12 col-sm-12 col-12">
                                            <div className="banner-text">
                                                <h1>Out of Town<br/>Services</h1>
                                                <p style={window.innerWidth <= 760 ? {fontSize: 18} :{}}>We provide out-of-town delivery services to customers located within a 200km radius of the Greater Toronto Area (GTA), with next-day delivery as the only available option.</p>
                                                <Link className="btn btn-theme" data-toggle="modal" data-target="#exampleModal">Get A Quote</Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </OwlCarousel>
                </div>
                {/*<div className="banner_social_icon">*/}
                {/*    <ul>*/}
                {/*        <li><a href="#!"><i className="fab facebook fa-facebook-f"></i></a></li>*/}
                {/*        <li><a href="#!"><i className="fab twitter fa-twitter"></i></a></li>*/}
                {/*        <li><a href="#!"><i className="fab instagram fa-instagram"></i></a></li>*/}
                {/*        <li><a href="#!"><i className="fab linkedin fa-linkedin-in"></i></a></li>*/}
                {/*    </ul>*/}
                {/*</div>*/}
            </section>

            <div className="modal fade" id="exampleModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel"
                 aria-hidden="true">
                <div className="modal-dialog modal-lg" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <img src={logo} alt="logo" style={{height:50}}/>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <RequestQuoteForm/>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default HomeBanner
