import React, {useState} from 'react'
// Import LogisticsCard
import LogisticsCard from './LogisticsCard'
// Import LogisticsData
import {LogisticsData} from './Logistics_Data'
// import Section Heading 
import SectionHeading from '../../Common/SectionHeading'
//  OwlCarousel Slider Import
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

const LogisticsService = () => {
  const [sliderIndex, setSliderIndex] = useState(0)


  let responsive = {
    0: {
      items: 1,
    },
    600: {
      items: 1,
    },
    960: {
      items: 2,
    },
    1200: {
      items: 4,
    },
  }

  return (
    <>
      <section id="logistics_area">
        <div className="container">
          <SectionHeading heading="Services"
                          para="Solving your supply chain needs whether scheduled in advance or rush delivery."/>
          <div className="row pt-5">
            {LogisticsData[0].map((data, index) => (
              <div className="col-12 col-md-6 col-lg-3 px-1" style={LogisticsData[0].length - 1 > index ? {marginBottom:35} :{}}>
                <LogisticsCard img={data.img} heading={data.heading} para={data.para}
                               key={index}/>
              </div>))}
          </div>
        </div>
      </section>
    </>
  )
}

export default LogisticsService;
