import React from 'react'
import {BrowserRouter as Router, BrowserRouter, Route, Switch} from 'react-router-dom';
// Import Page Layout
import Navbar from './component/Common/Navbar'
import Footer from './component/Common/Footer'
import CopyRight from './component/Common/CopyRight'
// Import All Components
import Home_One from './page/index'
import About from './page/About'
import Service from './page/Service'
import ServiceDetails from './page/ServiceDetails'
import Testimonials from './page/Testimonial'
import Faqs from './page/Faqs'
import Error from './page/Error'
import Contact from './page/Contact'
import RequestQuote from './page/RequestQuote'

// Import ScrollToTop Components
import ScrollToTop from './component/ScrollToTop'
import {ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Privacy = React.lazy(() => import('./page/PrivacyPolicy'))
const Terms = React.lazy(() => import('./page/TermsCondition'))
const App = () => {
  return (
    <>
      <React.Suspense fallback={
        <div className="min-vh-100 d-flex align-items-center justify-content-center">
        </div>
      }>
        <BrowserRouter>
          <Router>
            <ScrollToTop>
              <Navbar/>
              <Switch>
                <Route path='/' exact component={Home_One}/>
                <Route path='/about' exact component={About}/>
                <Route path='/service' exact component={Service}/>
                <Route path='/service_details' exact component={ServiceDetails}/>
                <Route path='/testimonials' exact component={Testimonials}/>
                <Route path='/faqs' exact component={Faqs}/>
                <Route path='/request_quote' exact component={RequestQuote}/>
                <Route path='/privacy-policy' exact component={Privacy}/>
                <Route path='/terms' exact component={Terms}/>
                <Route path='/contact' exact component={Contact}/>
                <Route exact component={Error}/>
              </Switch>
              <Footer/>
              <CopyRight/>
              <ToastContainer/>
            </ScrollToTop>
          </Router>
        </BrowserRouter>
      </React.Suspense>
    </>
  )
}

export default App
