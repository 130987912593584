import React, {useState} from 'react'
// Import FormInput
import FormInput from "../Common/FormInput";
import {toast} from "react-toastify";
// QuestionForm Area
const QuestionForm = () => {
    const [sendingEmail, setSendingEmail] = useState(false);
    const [completed, setCompleted] = useState(false);

    function submit(event) {
        event.preventDefault();

        const body = '<div style="font-size: 16px"><b><u>QUESTION:</u></b>' +
          '<br><br>' +
          '<b>Name:</b> ' + event.target.name.value + '<br>' +
          '<b>Email:</b> ' + event.target.email.value + '<br>' +
          '<b>Subject:</b> ' + event.target.subject.value + '<br><br>' +
          '<b>Message:</b> <br>' + event.target.message.value.replace(/\r\n|\r|\n/g, "<br />") + '<br>' +
          '</div>';
        const secureToken = "44de5176-76f7-47fa-8f0d-2b7136087ab0"
        const toEmail = 'ali@delightlogistics.ca';
        const fromEmail = 'ali@delightlogistics.ca';
        const subject = "Question from: " + event.target.name.value;
        const successMessage = 'Email sent to Delight Logistics!';

        setSendingEmail(true)
        setCompleted(false);
        window.Email.send({
            SecureToken: secureToken,
            To: toEmail,
            From: fromEmail,
            Subject: subject,
            Body: body,
        }).then(
          message => {
              toast.success(successMessage);
              setCompleted(true);
          }
        )
    }
    return (
        <>
            <div className="faqs_form">
                <div className="faqs_form_heading">
                    <h3>ASK YOUR QUESTIONS</h3>
                </div>
                <form id="request_form" onSubmit={(e) => submit(e)}>
                    <FormInput
                        tag={'input'}
                        type={'text'}
                        name={'name'}
                        classes={'form-control'}
                        placeholder={'Your Name'}
                    />
                    <FormInput
                        tag={'input'}
                        type={'text'}
                        name={'email'}
                        classes={'form-control'}
                        placeholder={'Your Email'}
                    />
                    <FormInput
                        tag={'input'}
                        type={'text'}
                        name={'subject'}
                        classes={'form-control'}
                        placeholder={'Your Subject'}
                    />
                    <FormInput
                        tag={'textarea'}
                        type={'text'}
                        name={'message'}
                        classes={'form-control'}
                        placeholder={'Type Your Messages'}
                    />
                    <div className="faqs_form_button">
                        <button className="btn btn-theme" disabled={sendingEmail}>Submit</button>
                    </div>
                    <br/>
                    {completed ? <label style={{color: 'green'}}>Email sent to Delight Logistics! </label> : null}
                </form>
            </div>
        </>
    )
}

export default QuestionForm
