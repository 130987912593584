import React from 'react'
// Import SectionHeading
import SectionHeading from '../Common/SectionHeading'
// Import ServiceCard
// Import ServiceData
import {LogisticsDataService} from "../Home_One/Logistics_Services/Logistics_Data";
import LogisticsCardBig from "../Home_One/Logistics_Services/LogisticsCardBig";

const ServicesCard = () => {
  return (
    <>
      <section id="services_page">
        <div className="container">
          <SectionHeading heading="Our Services"/>
          <div className="service_wrapper_top">
            <div className="row pt-5">
              {LogisticsDataService[0].map((data, index) => (
                <div className="col-12 col-md-6 mb-5">
                  <LogisticsCardBig img={data.img} heading={data.heading} para={data.para}
                                    key={index}/>
                </div>))}
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default ServicesCard
