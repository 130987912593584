import React from 'react'
import CommonBanner from '../component/Common/Banner'
import ServicesCard from '../component/Service/ServicesCard'
import Counter from '../component/Common/Counter'
import HomeTwoAbout from '../component/Home_Two/About'
import OurPartner from '../component/Common/OurPartner'
import {Helmet} from "react-helmet";

const Service = () => {
  function renderMetaTags() {
    return (
      <>
        <Helmet>
          <title>Services | Delight Logistics</title>


          <meta itemProp="name" content="Services | Delight Logistics"
                data-react-helmet="true"/>
          <meta itemProp="description"
                content="Delight Logistics - Local next or same day freight delivery service within the Greater Toronto Area and surrounding cities."/>
          <meta itemProp="image" content="https://www.delightlogistics.ca/delight-logistics-logo.png"
                data-react-helmet="true"/>
          <meta name="description"
                content="Delight Logistics - Local next or same day freight delivery service within the Greater Toronto Area and surrounding cities."/>
          <meta property="og:title" content="Services | Delight Logistics"
                data-react-helmet="true"/>
          <meta property="og:description"
                content="Delight Logistics - Local next or same day freight delivery service within the Greater Toronto Area and surrounding cities."/>
          <meta property="og:url" content="https://www.delightlogistics.ca"/>
          <meta property="og:type" content="website"/>
          <meta property="og:image" content="https://www.delightlogistics.ca/delight-logistics-logo.png"/>
          <meta name="twitter:title" content="Services | Delight Logistics"
                data-react-helmet="true"/>
          <meta name="twitter:description"
                content="Delight Logistics - Local next or same day freight delivery service within the Greater Toronto Area and surrounding cities."/>
          <meta name="twitter:card" content="summary_large_image"/>
          <meta name="twitter:image" content="https://www.delightlogistics.ca/delight-logistics-logo.png"/>

        </Helmet>
      </>
    )
  }

  return (
    <>
      {renderMetaTags()}
      <CommonBanner heading="Services" page="Services"/>
      <ServicesCard/>
      {/*<Counter/>*/}
      {/*<HomeTwoAbout/>*/}
      {/*<OurPartner/>*/}
    </>
  )
}

export default Service
