import React from 'react'

const LogisticsCardBig = (props) => {
  return (
    <>
      <div className="item">
        {/*<div className="w-100" style={{height:297}}>*/}
          <img className={'w-100'} style={{backgroundPositionY: 'center', height: 297, objectFit: 'cover'}} src={props.img} alt="Image_Card"/>
        {/*</div>*/}
        <div className="logistics_item_text">
          <h4><span className="bold">{props.heading}</span></h4>
          <p>{props.para}</p>
        </div>
      </div>
    </>
  )
}

export default LogisticsCardBig
