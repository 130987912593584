import img1 from '../../../assets/img/15a.jpg'
import img2 from '../../../assets/img/16.jpg'
import img3 from '../../../assets/img/3b.png'
import img4 from '../../../assets/img/9.png'

export const LogisticsData = [
    [
        {
            img: img1,
            heading: "Overnight Delivery",
            para: "Requests must be submitted by 3pm."
        },
        {
            img: img2,
            heading: "Same Day Delivery",
            para: "Requests must be submitted by 11pm."
        },
        {
            img: img3,
            heading: "Rush Delivery",
            para: "Requests submitted by 2pm to be delivered same day."
        },
        {
            img: img4,
            heading: "Liftgate Service",
            para: "All of our trucks are equipped with liftgates."
        }
    ]
]

export const LogisticsDataService = [
    [
        {
            img: img1,
            heading: "Overnight Delivery",
            para: "Requests submitted by 3pm. Will be delivered by end of next business day."
        },
        {
            img: img2,
            heading: "Same Day Delivery",
            para: "Requests submitted by 11pm. Will be delivered by end of next business day."
        },
        {
            img: img3,
            heading: "Rush Delivery",
            para: "Requests submitted by 2pm. Will be delivered same day before end of business day."
        },
        {
            img: img4,
            heading: "Liftgate Service",
            para: "To cater to locations with no loading dock or remote destinations that lack conventional loading docks, we have equipped all our trucks with liftgates. This enhances the versatility of our fleet and enables us to collect and deliver goods from such locations."
        }
    ]
]