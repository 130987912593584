import React from 'react'
// Import TestimonialsData
import {TestimonialsData} from '../Testimonial/TestimonialData'
// Import ClientLogos
import ClientLogos from '../Testimonial/Client_Logo'
// import Section Heading 
import SectionHeading from '../../Common/SectionHeading'
//  OwlCarousel Slider Import
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import {Link} from "react-router-dom";

const Testimonials = () => {

  let responsive = {
    0: {
      items: 1,
    },
    600: {
      items: 1,
    },
    960: {
      items: 1,
    },
    1200: {
      items: 2,
    },
  }

  return (
    <>
      <section id="client_review_area" className="banner-item" style={{paddingBottom: 100}}>
        <div className="container">
          <SectionHeading heading="Client's Review" para=""/>
          <div className="row">
            <div className="col-lg-12">
              <div className="client_sliders_wrappers">
                <OwlCarousel className="owl-theme" margin={30} responsive={responsive} autoplay={true}
                             dots={false} nav={true} autoplayHoverPause={true} autoplayTimeout={5000}>
                  {TestimonialsData.map((data, index) => (
                    <div className="client_items" key={index}>
                      <i className="fas fa-quote-right fa-3x"></i>
                      <div className="testimonial-box">
                        <span>
                           <i className="fas fa-star"></i>
                            <i className="fas fa-star"></i>
                            <i className="fas fa-star"></i>
                           <i className="fas fa-star"></i>
                           <i className="fas fa-star"></i>
                        </span>
                        <p>{data.para}</p>
                      </div>
                    </div>
                  ))}
                </OwlCarousel>
              </div>
              <div className="review_button">
                <Link to="/testimonials" className="btn btn-theme">More Review</Link>
              </div>
            </div>
          </div>
        </div>
        {/*<ClientLogos/>*/}
      </section>
    </>
  )
}

export default Testimonials
