import React from 'react'
import {Link} from 'react-router-dom';
// Import Img
import img1 from '../../../assets/img/14.jpg'

const HomeTwoAbout = () => {
    return (
        <>
            <section id="service_about">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                            <div className="about_service_img">
                                <img src={img1} alt="img-about"/>
                            </div>
                        </div>
                        <div className="col-lg-6  col-md-12 col-sm-12 col-12">
                            <div className="about_service_text">
                                <div className="heading-left-border">
                                    <h2>From Humble Beginnings</h2>
                                </div>
                                <p>Delight Logistics was established in 2006 as a family-owned enterprise serving businesses within the greater Toronto area. Over time, we have expanded alongside our clients, supporting their businesses with our unparalleled services. Our team functions like a closely-knit unit, prioritizing our clients' satisfaction above all else. Our personnel are trained to handle orders with the utmost care and professionalism, and our fleet is regularly maintained for optimal reliability. All these factors are crucial in fulfilling our commitment to providing a hassle-free delivery experience.</p>
                                <p>Ultimately, our goal at Delight Logistics is to exceed our clients' expectations and ensure their complete satisfaction. We recognize that our success hinges on your support!</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default HomeTwoAbout
