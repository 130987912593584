import React, {useEffect, useState} from 'react'
import HomeBanner from '../component/Home_One/Banner'
import LogisticsService from '../component/Home_One/Logistics_Services'
import OurAdvantage from '../component/Home_One/Our_Advantages'
import MapArea from '../component/Home_One/Map'
import Testimonials from '../component/Home_One/Testimonial'
import {Helmet} from "react-helmet";
import {Modal, ModalBody, ModalHeader} from "reactstrap";
import {Link} from "react-router-dom";
import redPattern from '../assets/img/red-pattern.jpg'
const localStorageKey = '@DELIGHT_LOGISTICS_POP_STATUS';

const Index = () => {
  const [open, setOpen] = useState(false);
  useEffect(()=>{
    let pop_status = localStorage.getItem(localStorageKey);
    if(!pop_status){
      setOpen(true);
      localStorage.setItem(localStorageKey,1);
    }
  },[])

  function renderMetaTags() {
      return (
        <>
          <Helmet>
            <title>Delight Logistics - LTL Freight Delivery</title>


            <meta itemProp="name" content="Delight Logistics - LTL Freight Delivery" data-react-helmet="true"/>
            <meta itemProp="description" content="Delight Logistics - Local next or same day freight delivery service within the Greater Toronto Area and surrounding cities."/>
            <meta itemProp="image" content="https://www.delightlogistics.ca/delight-logistics-logo.png" data-react-helmet="true"/>
            <meta name="description" content="Delight Logistics - Local next or same day freight delivery service within the Greater Toronto Area and surrounding cities."/>
            <meta property="og:title" content="Delight Logistics - LTL Freight Delivery" data-react-helmet="true"/>
            <meta property="og:description" content="Delight Logistics - Local next or same day freight delivery service within the Greater Toronto Area and surrounding cities."/>
            <meta property="og:url" content="https://www.delightlogistics.ca"/>
            <meta property="og:type" content="website"/>
            <meta property="og:image" content="https://www.delightlogistics.ca/delight-logistics-logo.png"/>
            <meta name="twitter:title" content="Delight Logistics - LTL Freight Delivery" data-react-helmet="true"/>
            <meta name="twitter:description" content="Delight Logistics - Local next or same day freight delivery service within the Greater Toronto Area and surrounding cities."/>
            <meta name="twitter:card" content="summary_large_image"/>
            <meta name="twitter:image" content="https://www.delightlogistics.ca/delight-logistics-logo.png"/>

          </Helmet>
        </>
      )
  }
  function renderModal() {
      return (
        <>
          <Modal isOpen={open} toggle={() => setOpen(false)} centered={true} size={'lg'} height={500}>
            <ModalBody className={'p-0'}>
              <div className='row '>
              <div className="d-none d-lg-block col-lg-5 ad p-0">
                <img src={redPattern} width="100%"
                     height="100%" style={{height: '100%'}}/>
              </div>
                <div className="details col-12 col-lg-7 px-5 py-5 my-3">
                  <h2 className='mb-4'>Special 10% offer</h2>
                  <p>Get a 10% discount as a new customer</p>
                  <p><small className="para">Fill out a Request form and we will contact you to get started.</small></p>
                  <Link className="btn btn-theme" to="/request_quote">Get Started</Link>
                </div>
              </div>
            </ModalBody>
          </Modal>
        </>
      )
  }

  return (
        <>
          {renderMetaTags()}
            <HomeBanner/>
            {/*<GetSchedule/>*/}
            <LogisticsService/>
            {/*<HomeAbout/>*/}
            <OurAdvantage/>
            <MapArea/>
            <Testimonials/>
            {/*<PricingTable/>*/}
            {/*<Subscribe/>*/}
            {/*<BlogHome/>*/}
          {renderModal()}
        </>
    )
}

export default Index
