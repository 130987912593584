import img1 from '../../../assets/img/home1/client1.png'
import logo1 from '../../../assets/img/home1/partner-1.png'
import logo2 from '../../../assets/img/home1/partner-2.png'
import logo3 from '../../../assets/img/home1/partner-3.png'
import logo4 from '../../../assets/img/home1/partner-4.png'
import logo5 from '../../../assets/img/home1/partner-5.png'


export const TestimonialsData = [
    {
        para: `I have been using Delight Logistics for all of my shipping needs for the past year and I couldn't be happier with their services. Their team is professional, reliable, and always goes above and beyond to ensure my freight is delivered on time. I highly recommend them to anyone in need of top-notch logistics services.`,
        img: img1,
        name: "David Huxham",
        dest: "Founder, Huxham Inc"
    },
    {
        para: `I recently had a time-sensitive shipment that needed to be delivered across the GTA and Delight Logistics came to the rescue. Their team was quick to respond to my inquiries and kept me updated every step of the way. The shipment was delivered exactly when promised, and in perfect condition. I am truly grateful for their excellent services.`,
        img: img1,
        name: "David Huxham",
        dest: "Founder, Huxham Inc"
    },
    {
        para: `I have been using Delight Logistics for all of my business shipments and I am constantly impressed by their efficiency and reliability. They always deliver my orders on time and in perfect condition. They are professional and have great customer service. Highly recommend!`,
        img: img1,
        name: "David Huxham",
        dest: "Founder, Huxham Inc"
    },
    {
        para: `As a small business owner, I am always on the lookout for cost-effective and efficient logistics solutions. Delight Logistics has exceeded my expectations in every way. They offer competitive pricing and their delivery times are always accurate. I have complete trust in their team to handle my shipments with the utmost care.`,
        img: img1,
        name: "David Huxham",
        dest: "Founder, Huxham Inc"
    },
    {
        para: `Delight Logistics has been a lifesaver for my business. Their quick and affordable delivery options have allowed me to expand my reach and grow my business. Thank you!`,
        img: img1,
        name: "David Huxham",
        dest: "Founder, Huxham Inc"
    },
    {
        para: `The transparency and communication provided by Delight Logistics is unmatched. I am always informed about the status of my shipments and any potential issues are quickly resolved. I highly recommend their services.`,
        img: img1,
        name: "David Huxham",
        dest: "Founder, Huxham Inc"
    },
]


export const ClientLogo = [
    {
        img: logo1
    },
    {
        img: logo2
    },
    {
        img: logo3
    },
    {
        img: logo4
    },
    {
        img: logo5
    },
]