import React from 'react'

const LogisticsCard = (props) => {
    return (
        <>
            <div className="item">
                <div className="w-100">
                    <img className={'w-100'} src={props.img} style={{objectFit: 'cover', height: 277}} alt="Image_Card"/>
                </div>
                <div className="logistics_item_text">
                    <h4><span className="bold">{props.heading}</span></h4>
                    <p>{props.para}</p>
                </div>
            </div>
        </>
    )
}

export default LogisticsCard
