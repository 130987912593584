import React from 'react'
// OurAdvantageCard Area
const OurAdvantageCard = (props) => {
    console.log(props)
    return (
        <>
            <div className=" row  align-items-center  justify-content-center mb-5">
                <div className="advantages_icons col-12 col-sm-3 col-md-2 " style={{maxWidth:100}}>
                    <div className={props.clazz} style={{marginTop:20}}>
                    {/*<i className={props.clazz}></i>*/}
                    </div>
                </div>
                <div className="advantages_text col-12 col-sm-9 text-center text-sm-left">
                    <h4>{props.heading}</h4>
                    <p>{props.para}</p>
                </div>
            </div>
        </>
    )
}

export default OurAdvantageCard
