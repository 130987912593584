import React from 'react'
import {Link} from 'react-router-dom';

// TopHeader Area
const TopHeader = () => {
  return (
    <>
      <div className="top-header">
        <div className="container d-none d-sm-block">
          <div className="row align-items-center">
            <div className="col-lg-6 col-md-12 col-sm-12 col-12">
              <ul className="left-info">
                <li>
                  <a href="mailto:info@delightlogistics.ca">
                    <i className="far fa-envelope"></i>
                    info@delightlogistics.ca
                  </a>
                </li>
                <li>
                  <a href="tel:(416) 477-1336">
                    <i className="fas fa-phone-volume"></i>
                    (416) 477-1336
                  </a>
                </li>
              </ul>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 col-12">
              <ul className="right-info">

                <li className="mr-20">
                  <Link to="/contact">Mon - Fri: 6:00 - 20:00</Link>
                </li>

                <li className="mr-20">
                  <div>|</div>
                </li>

                <li className="mr-20">
                  <Link to="/request_quote">Get a 10% discount as a new customer</Link>
                </li>

              </ul>
            </div>
          </div>
        </div>
          <div className="container d-sm-none">
          <div className="row align-items-center">

            <div className="col-lg-6 col-md-12 col-sm-12 col-12">
              <ul className="right-info">

                <li className="mr-20">
                  <Link to="/request_quote">Get a 10% discount as a new customer</Link>
                </li>

              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default TopHeader
