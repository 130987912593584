import React, {useState} from 'react'
import FormInput from '../Common/FormInput'
import {toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const skidsInput = [
  {text: "Please Select", value: ""},
  {text: "1", value: "1"},
  {text: "2", value: "2"},
  {text: "3", value: "3"},
  {text: "4", value: "4"},
  {text: "5", value: "5"},
  {text: "6", value: "6"},
  {text: "7", value: "7"},
  {text: "8", value: "8"},
  {text: "9", value: "9"},
  {text: "10", value: "10"},
  {text: "11", value: "11"},
  {text: "12", value: "12"},
  {text: "More than 12", value: "More than 12"},
]

const RequestQuoteForm = () => {
  const [selectedFile, setSelectedFile] = useState();
  const [sendingEmail, setSendingEmail] = useState(false);
  const [completed, setCompleted] = useState(false);

  const getBase64 = file => {
    return new Promise(resolve => {
      let baseURL = "";
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        baseURL = reader.result;
        resolve(baseURL);
      };
    });
  };

  function submit(event) {
    event.preventDefault();

    const body = '<div style="font-size: 16px"><b><u>REQUEST FOR QUOTE:</u></b>' +
      '<br><br>' +
      '<b>Company Name:</b> ' + event.target.cname.value + '<br>' +
      '<b>Email:</b> ' + event.target.email.value + '<br>' +
      '<b>Phone:</b> ' + event.target.phone.value + '<br><br>' +
      '<b>Number of Skids:</b> ' + event.target.skids.value + '<br>' +
      '<b>Weight:</b> ' + event.target.weight.value + '<br>' +
      '<b>Pickup:</b> ' + new Date(event.target.pickup.value).toDateString() + ' ' + new Date(event.target.pickup.value).toLocaleTimeString() + '<br>' +
      '<b>Delivery:</b> ' + new Date(event.target.delivery.value).toDateString() + ' ' + new Date(event.target.delivery.value).toLocaleTimeString() + '<br>' +
      '<b>Shipper:</b> ' + event.target.shipper.value + '<br>' +
      '<b>Consignee:</b> ' + event.target.consignee.value + '<br><br>' +
      '<b>Special Instructions:</b> <br>' + event.target.instructions.value.replace(/\r\n|\r|\n/g, "<br />") + '<br>' +
      '</div>';
    const secureToken = "44de5176-76f7-47fa-8f0d-2b7136087ab0"
    const toEmail = 'ali@delightlogistics.ca';
    const fromEmail = 'ali@delightlogistics.ca';
    const subject = "Request Quote from: " + event.target.cname.value;
    const successMessage = 'Quote sent to Delight Logistics!';
    const errorMessage = 'Unable to send Quote, Please try again.';

    setSendingEmail(true)
    setCompleted(false);
    if (selectedFile) {
      getBase64(selectedFile).then(result => {
        window.Email.send({
          SecureToken: secureToken,
          To: toEmail,
          From: fromEmail,
          Subject: subject,
          Body: body,
          Attachments: [
            {
              name: selectedFile.name,
              data: result
            }]
        }).then(
          message => {
            toast.success(successMessage);
            setCompleted(true);
          }
        )
      }).catch(err => {
        setSendingEmail(false)
        console.log(err);
        toast.error(errorMessage)
      });
    } else {
      window.Email.send({
        SecureToken: secureToken,
        To: toEmail,
        From: fromEmail,
        Subject: subject,
        Body: body,
      }).then(
        message => {
          toast.success(successMessage);
          setCompleted(true);
        }
      )
    }
  }

  const changeHandler = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  return (
    <div className="container">
      <div className="row">
        <div className="col-lg-12 col-sm-12 col-md-12 col-12">
          <form id="request_form" onSubmit={(e) => submit(e)}>
            <div className="row">
              <div className="col-lg-12">
                <div className="heading_quote">
                  <h3>Get a quote</h3>
                </div>
              </div>
              <div className="col-lg-6">
                <FormInput
                  tag="select"
                  required={true}
                  name={"skids"}
                  classes="form-control"
                  options={skidsInput}
                  label="Number of skids"
                />
              </div>
              <div className="col-lg-6">
                <FormInput
                  tag={"input"}
                  type={"number"}
                  name={"weight"}
                  required={true}
                  classes={"form-control"}
                  label="Actual weight (lbs) "
                />
              </div>
              <div className="col-lg-6">
                <FormInput
                  tag={"birthdaytime"}
                  required={true}
                  type={"datetime-local"}
                  name={"pickup"}
                  classes={"form-control"}
                  label="Pickup Date/Time"
                />
              </div>
              <div className="col-lg-6">
                <FormInput
                  tag={"birthdaytime"}
                  required={true}
                  type={"datetime-local"}
                  name={"delivery"}
                  classes={"form-control"}
                  label="Delivery Date/Time"
                />
              </div>
              <div className="col-lg-12">
                <div className="heading_quote arae_top">
                  <h3>Your Personal Details</h3>
                </div>
              </div>
              <div className="col-lg-12">
                <FormInput
                  tag={"input"}
                  type={"text"}
                  name={"cname"}
                  required={true}
                  classes={"form-control"}
                  placeholder={"Company Name"}
                  label="Company Name"
                />
              </div>
              <div className="col-lg-6">
                <FormInput
                  tag={"input"}
                  type={"text"}
                  name={"email"}
                  required={true}
                  classes={"form-control"}
                  placeholder={"Email"}
                  label="Your Email"
                />
              </div>
              <div className="col-lg-6">
                <FormInput
                  tag={"input"}
                  type={"number"}
                  name={"phone"}
                  classes={"form-control"}
                  placeholder={"Phone Number"}
                  label="Phone Number"
                />
              </div>
              <div className="col-lg-12">
                <FormInput
                  tag={"input"}
                  required={true}
                  type={"text"}
                  name={"shipper"}
                  classes={"form-control"}
                  placeholder={"Shipper"}
                  label="Shipper (Company / Street Name & Number / City)"
                />
              </div>
              <div className="col-lg-12">
                <FormInput
                  tag={"input"}
                  required={true}
                  type={"text"}
                  name={"consignee"}
                  classes={"form-control"}
                  placeholder={"Consignee"}
                  label="Consignee (Company / Street Name & Number / City)"
                />
              </div>
              <div className="col-lg-12">
                <FormInput
                  tag={"textarea"}
                  type={"text"}
                  name={"instructions"}
                  classes={"form-control"}
                  label="Description / Reference / Special Instructions"
                />
              </div>
              <div className="col-lg-12">
                <div className="quote_submit_button">
                  <input type="file" name="file" onChange={changeHandler}/>
                </div>
              </div>
              <div className="col-lg-12">
                <div className="quote_submit_button">
                  <button className="btn btn-theme" disabled={sendingEmail}>Request Quote</button>

                </div>
                <br/>
                {completed ? <label style={{color: 'green'}}>Quote sent to Delight Logistics! </label> : null}
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default RequestQuoteForm
